@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input {
    @apply w-full p-2 border border-silver-300 rounded-lg text-silver-800 text-sm;
  }

  .label {
    @apply text-sm text-silver-600 block mb-1;
  }

  .button {
    @apply bg-primary-500 hover:bg-primary-600 disabled:bg-silver-600 text-white text-base rounded-full py-2 px-5 font-medium cursor-pointer;
  }

  .button:disabled {
    @apply bg-silver-200 text-silver-400;
  }

  .borderedButton {
    @apply border border-silver-300 hover:bg-silver-200 rounded-full py-2 px-5 text-base text-silver-800 font-medium cursor-pointer;
  }

  .blackButton {
    @apply bg-black text-white rounded-full py-2.5 px-5 text-base font-medium cursor-pointer;
  }

  .redButton {
    @apply bg-error-200 hover:bg-error-300 disabled:bg-silver-600 text-white text-base rounded-full py-2 px-5 font-medium cursor-pointer;
  }

  .grid-header {
    @apply text-silver-700 font-bold border-b border-silver-300;
  }
}

html,
body,
#root {
  height: 100%;
  font-family: "Montserrat", sans-serif !important;
}

.text-gray {
  opacity: 0.8;
}

p,
.textBlackColor,
.textBlackColor p,
.textBlackColor li {
  color: #333;
}

.button:focus-visible,
.borderedButton:focus-visible {
  border: 1px solid #009c9d;
  -webkit-box-shadow: 0px 0px 0px 3px rgba(0, 175, 170, 0.15);
  -moz-box-shadow: 0px 0px 0px 3px rgba(0, 175, 170, 0.15);
  box-shadow: 0px 0px 0px 3px rgba(0, 175, 170, 0.15);
  outline: none;
}

.openSidebar {
  height: 100%;
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.openSidebar .rightSideContent {
  width: calc(100% - 400px);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.openSidebar .sideBarWidth {
  width: 400px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.openSidebar .rightSideContent {
  overflow: visible !important;
}

.openSidebar .customTable {
  width: 110%;
}

input::placeholder {
  color: #808080 !important;
  font-size: 14px;
  padding-left: 5px;
  line-height: 100% !important;
  font-family: "Montserrat", sans-serif !important;
}

input:-ms-input-placeholder {
  color: #808080 !important;
  font-size: 14px;
  padding-left: 5px;
  line-height: 100% !important;
  font-family: "Montserrat", sans-serif !important;
}

input:-ms-input-placeholder {
  color: #808080 !important;
  font-size: 14px;
  padding-left: 5px;
  line-height: 100% !important;
  font-family: "Montserrat", sans-serif !important;
}


.topAlignedTable table td {
  vertical-align: top;
}

.border-none {
  border-style: none !important;
}

.iconHover:hover+.toolTip,
.iconHover:hover+.toolTip {
  display: block;
  z-index: 2;
}

.toolTip::before {
  position: absolute;
  content: "";
  left: 50%;
  top: -6px;
  transform: translate(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #000000 transparent;
}

.customTable tr:last-child .iconHover:hover+.toolTip {
  top: -39px;
}

.customTable tr:last-child .toolTip::before {
  bottom: -6px;
  top: auto;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #000000 transparent transparent transparent;
}

.customTable tr:first-child .iconHover:hover+.toolTip {
  top: 2rem;
}

.customTable tr:first-child .toolTip::before {
  bottom: auto;
  top: -6px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #000000 transparent;
}

.countrySelect input:focus {
  box-shadow: none;
}

.removeBtn:hover rect:first-child {
  fill: red;
}

.removeBtn:hover path {
  stroke: white;
}

.trashBtn:hover path {
  stroke: red;
}

.disabledArrow svg path {
  stroke: #d5d5d5;
}

button:disabled svg path {
  stroke: #ccc;
}

.toolTipBar:hover+.hidden {
  display: block !important;
}

.iconHover:hover svg path,
.toolTipBar:hover svg circle,
.toolTipBar:hover svg path {
  stroke: #008581;
}

.userIcon svg {
  width: 18px;
  height: 18px;
}

.userIcon svg path {
  stroke: #777;
}

/* .toolTipBox { position: relative; } */
.toolTipBox:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 10px;
  left: 1px;
  box-sizing: border-box;

  border: 4px solid #656565;
  border-color: transparent transparent #f4f4f4 #f4f4f4;

  transform-origin: 0 0;
  transform: rotate(45deg);

  box-shadow: -1px 1px 1px 0 rgba(63, 63, 63, 0.4);
}

* {
  box-sizing: border-box;
}

.scrollHeight {
  max-height: calc(100vh - 200px);
}

h1 {
  font-size: 48px;
  line-height: 62px;
}

h2 {
  font-size: 40px;
  line-height: 52px;
}

h3 {
  font-size: 34px;
  line-height: 44px;
}

h4 {
  font-size: 28px;
  line-height: 36px;
}

h5 {
  font-size: 24px;
  line-height: 30px;
}

h6 {
  font-size: 20px;
  line-height: 26px;
}

.rightsideImageBox {
  background: url(./assets/signin_image.png) no-repeat center / cover;
}

.notificationIcon:hover svg path,
.editIcon:hover svg path {
  stroke: #ffffff;
}

.notificationSection .notificationItem:first-child .border-t-2 {
  border-top: none;
}

.userMenu {
  min-width: 200px;
}

.userMenu a.active svg path,
.userMenu a:hover svg path {
  stroke: #008581;
}

.userMenu a.active {
  color: #008581;
}

.trashIcon:hover svg path {
  stroke: #dc2626;
}

.closeIcon:hover svg path {
  fill: red;
}

.redTrashIcon:hover svg path {
  stroke: #dc2626;
}

input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.customCheckbox {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.customCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 15px;
  width: 15px;
  z-index: 9999;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.signupCheckbox .checkmark {
  top: 0px;
}

.customCheckbox input:checked~.checkmark {
  background-color: #009c9d;
  border: 1px solid #009c9d;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.customCheckbox input:checked~.checkmark:after {
  display: block;
}

.disabledField {
  background: rgba(0, 0, 0, 0.05);
}

.customCheckbox .checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.customRadiobox input[type="radio"]:checked,
.customRadiobox input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.customRadiobox input[type="radio"]:checked+label,
.customRadiobox input[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.customRadiobox input[type="radio"]:checked+label:before,
.customRadiobox input[type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background-color: #fff;
}

.customRadiobox input[type="radio"]:checked+label:before {
  background: #009c9d;
}

.customRadiobox input[type="radio"]:checked+label:after,
.customRadiobox input[type="radio"]:not(:checked)+label:after {
  content: "";
  width: 6px;
  height: 6px;
  background: #fff;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.customRadiobox input[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.customRadiobox input[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.customSelect {
  position: relative;
}

.customSelect:before {
  content: "";
  position: absolute;
  right: 5px;
  top: 10px;
  background-color: #fff;
  width: 12px;
  height: 15px;
  display: block;
  z-index: 9;
}

.customSelect:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 13px;
  border: solid #666666;
  border-width: 0 1px 1px 0;
  padding: 3px;
  display: block;
  z-index: 11;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.signinInfoBox {
  height: calc(100% - 120px);
}

input::-ms-reveal {
  display: none;
}

/* .required {
  position: relative;
  padding-left: 7px;
}

.required:before {
  position: absolute;
  content: "*";
  font-size: 12px;
  color: #ef4444;
  left: 0px;
  top: 0px;
} */

input:focus,
input:focus-visible,
select:focus,
select:focus-visible {
  border: 1px solid #009c9d;
  -webkit-box-shadow: 0px 0px 0px 3px rgba(0, 175, 170, 0.15);
  -moz-box-shadow: 0px 0px 0px 3px rgba(0, 175, 170, 0.15);
  box-shadow: 0px 0px 0px 3px rgba(0, 175, 170, 0.15);
  outline: none;
}

.noFocus input:focus,
.noFocus input:focus-visible,
.noFocus select:focus,
.noFocus select:focus-visible {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.disabledUnit p,
input:disabled {
  color: #b0aa9c;
}

input.disabled:focus,
input.disabled:focus-visible {
  border: 1px solid #f4f4f4;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.customSelectBox:focus-visible,
.customSelectBox:focus,
.customSelectBox div:focus,
.customSelectBox div:focused:hover {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  border: 1px solid #009c9d;
  -webkit-box-shadow: 0px 0px 0px 3px rgba(0, 175, 170, 0.15);
  -moz-box-shadow: 0px 0px 0px 3px rgba(0, 175, 170, 0.15);
  box-shadow: 0px 0px 0px 3px rgba(0, 175, 170, 0.15);
}

.customSelectBox input {
  box-shadow: none !important;
}

#react-select-3-listbox {
  border: 1px solid #ddd;
}

/* #react-select-3-listbox .abcd {
  color: red !important;
} */

/* .customSelectBox div:hover { border: none; } */

.has-error .input,
.has-error .input:focus,
.has-error .input:focus-visible,
.has-error .selectBox>div>div {
  border: 1px solid #ef4444 !important;
  outline: none !important;
  box-shadow: none;
}

input:-webkit-autofill {
  background: none !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
}

.headerBar {
  /* background: radial-gradient(circle 420px, #e3fdfc, #edf2f7); */
  background-repeat: no-repeat;
  background-color: #0a4b67;
}

.selectedTabItem.active {
  border: 1px solid #ffffff;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 24px;
  background-color: #fff;
  color: #000;
}

.selectedNavLink.active {
  color: #008581;
  background-color: #f4f4f4;
}

.selectedNavLink.active svg path {
  stroke: #008581;
}

.curvedBoxBg {
  background-color: #0a4b67;
}

.rounded-48 {
  border-radius: 48px 48px 0 0;
}

.accordion-header {
  position: relative;
}

.accordion-header:after {
  position: absolute;
  content: "";
  right: 10px;
  top: 50%;
  border: solid #888;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: -5px;
}

.accordion .accordion-item.show .accordion-header:after {
  transform: rotate(-135deg);
}

.accordion .accordion-item:not(.show) .collapse {
  display: none;
}

.accordion .collapse {
  visibility: visible !important;
}

.customTable tr:hover {
  background-color: #f4f4f4;
}

.customTable tr.noData:hover {
  background-color: transparent;
}

.customTable tr.noData td {
  height: calc(100vh - 400px);
}

.customTable tr .showMoreButton {
  display: none;
}

.customTable tr:hover .showMoreButton {
  display: inline-block !important;
  background-color: none;
}

/* .rightSideContent {
  height: calc(100vh - 300px);
} */

.sidebar {
  height: calc(100vh - 100px);
  padding-bottom: 20px;
}

.adminSidebar .sidebar {
  height: 100vh;
}

.sidebar .titleText {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 16px;
}

.sidebar .accordion-body {
  padding-top: 0;
}

.sidebar .accordion-item {
  padding-bottom: 0;
  margin-bottom: 0;
}

.pageTitle {
  position: relative;
}

.pageTitle:before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  border: solid #111;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.grid-row {
  border-bottom: 1px solid #dddddd;
}

.rectangle {
  background-color: #e6e6e6;
  font-size: 14px;
  color: #111;
  border-radius: 2px;
  padding: 3px;
  cursor: pointer;
}

.paginationItem {
  padding: 0 10px;
}

.paginationItemActive {
  border: 1px solid #009c9d;
  color: #009c9d;
  border-radius: 3px;
  padding: 0 6px;
}

.pagination button:disabled svg path {
  stroke: #ababab !important;
}

.pr-10 {
  padding-right: 10spx;
}

/* .backArrow {position: relative;}
.backArrow:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border: solid #111;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
} */

.chartInner {
  transform: translate(-50%, -82%);
}

.scrollableContent {
  height: calc(100vh - 340px);
  overflow-y: auto;
  overflow-x: auto;
}

.savedModal.scrollableContent {
  height: calc(100vh - 485px);
}

.inputPageGrid.inputPageGridBox .scrollableContent {
  height: calc(100vh - 352px);
}

.inputPageGrid .scrollableContent {
  height: calc(100vh - 330px);
}

.OpenEyeIcon svg path {
  stroke: #009c9d;
}

.CloseEyeIcon svg path {
  stroke: #b8b8b8;
}

.paginationItem {
  padding: 0 10px;
}

.paginationItemActive {
  border: 1px solid #009c9d;
  color: #009c9d;
  border-radius: 3px;
  padding: 0 6px;
}

.openDropDown {
  border-color: #009c9d;
  box-shadow: 0px 0px 0px 3px rgba(0, 175, 170, 0.15);
}

.openDropDown .downArrow {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.inputPageGrid.inputPageGridBox .mt-4 {
  margin-top: 0 !important;
}

.inputPageGridBox .customTable tr th:nth-child(1) {
  width: 85%;
}

.inputPageGridBox .customTable tr th:nth-child(2) {
  width: 15%;
}

.filterSelect,
.filterSelectBox,
.filterSelect+div {
  font-size: 14px;
}

.filterSelectBox select {
  border: none;
  outline: none;
}

.ActiveTab {
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.16);
}

.adminMenu svg {
  margin: 0 auto;
}

.adminMenu svg path {
  stroke: #b8b8b8;
}

.adminMenu a {
  display: block;
}

.adminMenu .active {
  background: #333;
}

.adminMenu .active svg path {
  stroke: #56d2ce;
}

.disabledButton .iconHover:hover svg path,
.disabledButton svg path {
  stroke: #b8b8b8;
}

.disabledButton .iconHover:hover {
  cursor: not-allowed;
  background-color: transparent;
  border: 1px solid transparent;
}

.disabledButton .iconHover:hover+.toolTip,
.disabledButton .iconHover:hover+.toolTip {
  display: none;
}

.tabsSection svg path {
  stroke: #b8b8b8;
}

.tabsSection .activeTab svg path,
.tabsSection .activeTab svg rect {
  stroke: #009c9d;
}

.customTostMsg {
  font-size: 13px;
}

.customTostMsg .Toastify__toast {
  border-radius: 10px;
  padding: 0 10px;
}

.customTostMsg .Toastify__toast-body {
  padding: 0;
}

.customTostMsg .Toastify__close-button {
  align-self: center;
}

/* .ModalBox { max-height: 100%; overflow-y: auto; } */
.ModalPopup .ModalContent {
  overflow-y: auto;
}

.customModel .ModalPopup .ModalContent {
  height: calc(100vh - 150px);
}

.customModel .ModalPopup  {
  overflow: hidden;
}

.customFieldModel .ModalPopup .ModalContent {
  overflow-y: visible;
}

.customFieldModel .customFieldInfo {
  height: 400px;
  overflow-y: auto;
}

.emmissionSummaryModel .ModalPopup {
  overflow: hidden;
  padding-right: 4px;
}

.emmissionSummaryModel .ModalPopup .ModalContent {
  height: 700px;
}

.shareModalPopup .ModalPopup .ModalContent {
  overflow-y: visible;
}

.modelOverlayBg {
  z-index: 45;
}

.customTable th {
  position: sticky;
  top: 0;
  background-color: #efefef;
  z-index: 9;
}

/* .openSideBar .rightSideContent {
  padding-top: 3rem;
} */

/* .highcharts-title,
.highcharts-data-labels .highcharts-label,
.highcharts-data-label-connector,
.highcharts-credits {
  display: none !important;
} */

.farmCalculatorForm .react-datepicker-wrapper {
  position: absolute;
  top: -10px;
  left: 0;
  z-index: -1;
  font-family: "Montserrat";
}

.feed-calculation {
  font-family: "Montserrat";
}

.farmInfo .react-datepicker-wrapper {
  position: unset;
}

/* .farmCalculatorForm .react-datepicker-wrapper input  { display: none; } */
.react-datepicker__triangle::before,
.react-datepicker__triangle::after {
  display: none !important;
}

.smallIcon svg {
  width: 16px;
  height: 16px;
}

.donutChartBox canvas {
  height: 400px !important;
  position: relative;
}

.shareModalPopup .input-select-label-container {
  align-items: center;
}

/* .shareModalPopup .profileIcon {
  width: 1.5rem;
  height: 1.5rem;
} */

.react-datepicker {
  width: 210px !important;
  border: 1px solid #f4f4f4 !important;
  box-shadow: 0px 6px 10px -1px rgba(6, 25, 56, 0.07);
}

.react-datepicker__year-wrapper {
  max-width: 210px !important;
}

.react-datepicker__header {
  border-bottom: 1px solid #f4f4f4 !important;
}

.react-datepicker-year-header {
  background-color: #fff;
}

.react-datepicker__navigation {
  top: 8px;
}

.react-datepicker__year-text:hover,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__year-text--selected {
  background-color: #e8fcfc !important;
  color: #008581 !important;
}

.react-datepicker__year-text,
.react-datepicker__header {
  font-family: "Montserrat", sans-serif !important;
}

.react-datepicker__year .react-datepicker__year-text {
  margin: 5px 0 !important;
  padding: 5px 0 !important;
}

.modelWidthOverflow {
  overflow-x: auto;
}

:root {
  scroll-behavior: smooth;
}

.profilePicture img {
  object-fit: contain;
  width: 100%;
  height: 140px;
}

.addUserButton {
  top: -7.5rem !important;
}

/* .has-error .selectBox .react-datepicker__input-container{border-radius: 0.5rem;} */

.calcLink:hover svg path {
  stroke: #009c9d;
}

.siteInformation .headerTabsBar {
  display: none;
}

.InnerTabBox .tabLists {
  display: block !important;
  border: none;
}

.InnerTabBox .tabLists li {
  border: none;
  margin-bottom: 0;
  padding: 20px;
  background-color: #f4f4f4;
  border-bottom: 2px solid #fff;
  margin-right: 0;
}

.InnerTabBox .tabLists li.active {
  background-color: #fff;
}

.InnerTabBox .tab-content {
  padding: 20px;
}

.calculatorMenu a {
  display: block;
}

.calculatorMenu .selectedTabItem.active {
  border: none;
  box-shadow: none;
  border-radius: none;
  color: #009c9d;
}

canvas {
  vertical-align: top;
  font-family: "Montserrat", sans-serif !important;
}

.hamburger {
  cursor: pointer;
}

.hamburger .line {
  width: 30px;
  height: 3px;
  background-color: #009c9d;
  display: block;
  margin: 6px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.buttonLoader .loader {
  width: 20px;
  height: 20px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #009c9d;
}

.disabledSection {
  position: relative;
  /* opacity: 0.3; */
}

.disabledSection:before {
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.textlink {
  color: #009c9d;
  font-weight: bold;
}

.textlink:hover {
  color: #000;
}

.Toastify--animate-icon+div {
  padding: 10px 10px 10px 5px;
  color: #333;
}

.customList {
  padding-left: 40px;
  margin: 20px 0;
}

.customList li {
  list-style-type: disc;
}

.titleIcon {
  background-color: #009c9d;
}

@media screen and (max-width: 1730px) {
  .downloadBtn {
    bottom: -30px;
  }
}

@media screen and (max-width: 1600px) {
  .tabsSection .tabIcon {
    display: none;
  }

  .scrollableContent table {
    width: 130%;
  }

  /* .sidebar {
    height: 100%;
  } */
}

@media screen and (max-width: 1439px) {
  .ingredientsTab .scrollableContent table {
    width: 150%;
  }

  .openSidebar .sideBarWidth {
    width: 340px;
  }

  .openSidebar .customTable {
    width: 140%;
  }
}

@media screen and (max-width: 1365px) {
  .modelWidthOverflow table {
    width: 100%;
  }

  .scrollableContent table {
    width: 130%;
  }

  .sidebar {
    height: calc(100vh - 100px);
  }
}

@media screen and (max-width: 1279px) {
  .scrollableContent table {
    width: 155%;
  }

  .speciesTable .scrollableContent table {
    width: 100%;
  }

  .commonTable .scrollableContent table {
    width: 220%;
  }

  .ingredientsTab .scrollableContent table {
    width: 290%;
  }

  .tableOverflowWidth {
    width: 150%;
  }

  .formHeight {
    height: 300px;
  }

  .chartTargetTitle {
    font-size: 14px;
    padding: 0 10px;
  }

  /* .chartInner {
    transform: translate(-50%, -85%);
  } */

  .inputTabList .speciesTable .paginationBar {
    display: block;
    margin: auto;
  }

  .inputTabList .paginationBar .flex {
    justify-content: center;
  }

  .inputTabList .paginationBar .paginationLine {
    margin-top: 15px;
  }

  .graphNumber {
    font-size: 22px !important;
    line-height: 20px;
  }

  .chartInner {
    transform: translate(-50%, -115%);
  }

  .sideBarWidth:before {
    content: "";
    position: absolute;
    left: -99999px;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 40;
  }
}

@media screen and (max-width: 1023px) {

  html,
  body,
  #root {
    height: auto;
  }

  .loaderBox {
    height: 100vh !important;
  }

  .buttonLoader .loaderBox {
    height: auto !important;
  }

  .rightsideImageBox {
    height: 250px;
  }

  .verticalCenterdInfo {
    height: calc(100vh - 140px);
  }

  .scrollableContent table {
    width: 230%;
  }

  .inputPageGrid .scrollableContent,
  .inputPageGrid.inputPageGridBox .scrollableContent,
  .savedModal.scrollableContent,
  .scrollableContent {
    height: auto;
    min-height: 200px;
  }

  .noDataBox {
    height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }

  .tableOverflowWidth {
    width: 100%;
  }

  .formHeight {
    height: 400px;
  }

  .highcharts-data-labels .highcharts-label,
  .highcharts-data-label-connector,
  .highcharts-credits {
    display: block !important;
  }

  .calculatorBox {
    display: flex;
    flex-direction: column;
  }

  .calcFormBox {
    order: 2;
  }

  .calcGraphBox {
    order: 1;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .chartInner {
    transform: translate(-50%, -95%);
  }

  .tabsSection .tabIcon {
    display: block;
  }
}

@media screen and (max-width: 767px) {

  html,
  body,
  #root {
    height: auto;
  }

  .rightsideImageBox {
    height: 200px;
  }

  .w-800 {
    width: 90%;
  }

  #miscellaneous-inputs .scrollableContent,
  .inputPageGrid.inputPageGridBox .scrollableContent,
  .verticalCenterdInfo {
    height: auto;
  }

  #miscellaneous-inputs .mb-20 {
    margin-bottom: 0;
  }

  /* .rightsideImageBox {
    display: none !important;
  } */

  .scrollableContent table {
    width: 240%;
  }

  .donutChartBox canvas {
    position: relative;
    font-size: 10px !important;
  }

  .upArrow {
    transform: rotate(-180deg);
  }

  .miscButton {
    top: -150px;
  }

  .modelWidthOverflow table {
    width: 130%;
  }

  /* .showMoreButton {
    top:110px;
    right: 20px;
    z-index: 99;
  } */
  .tableOverflowWidth .showMoreButton {
    display: none !important;
  }

  .noDataBox {
    height: auto;
    padding: 40px 0;
  }

  .pagination {
    margin-left: 0;
  }

  .topTooltip {
    top: -50px;
  }

  .topTooltip:before {
    top: auto;
    bottom: -9px;
    border-width: 10px 10px 0 10px;
    border-color: #000000 transparent transparent transparent;
  }

  .openSidebar .sideBarWidth {
    width: 400px;
  }

  .smoltTooltip.toolTipBox {
    display: none;
  }

  .toolTipBar:hover+.smoltTooltip.hidden {
    display: none !important;
  }

  .smoltInstruction .filterBarBox {
    margin-bottom: 55px;
  }

  .InnerTabBox {
    display: block !important;
  }

  .InnerTabBox .tabLists {
    display: flex !important;
    width: 100% !important;
  }

  .InnerTabBox .tabButton,
  .InnerTabBox .tabContent {
    width: 100%;
  }

  .cellHeading br { 
     display: none;
  }
}

@media screen and (max-width: 639px) {
  .notificationIcon svg {
    width: 18px;
    height: 18px;
  }

  .inputTabList .tabLists {
    width: 100%;
    overflow-x: auto;
  }

  .filterBarBox input {
    width: 100%;
  }

  .perPageButton {
    display: none;
  }

  .fourBtnBar .button,
  .fourBtnBar .borderedButton {
    padding: 5px 10px;
  }

  .openSidebar .sideBarWidth {
    width: 100%;
  }

  .openSidebarShow {
    padding-left: 0 !important;
  }

  .openSidebar.adminSidebar {
    z-index: 9999;
  }

  .openSidebar.adminSidebar .sideBarWidth {
    margin-right: 0;
  }

  /* .filterSelectBox {
    margin-top: 15px;
  } */
}

@media screen and (max-width: 567px) {
  .w-440 {
    width: 100%;
  }

  .signinInfoBox {
    height: auto;
    margin-bottom: 20px;
  }

  .signinInfoBox br {
    display: none;
  }

  .signinInfoBox h4 {
    font-size: 22px;
  }

  .w-480,
  .w-528 {
    width: 100%;
  }

  .scrollableContent table {
    width: 240%;
  }

  .chartTargetTitle {
    font-size: 16px;
  }

  .chartTarget {
    font-size: 22px;
  }

  .emmissionSummaryModel .tabBar {
    width: auto;
  }

  .emmissionSummaryModel .tabBar span {
    display: none;
  }

  .emmissionSummaryModel .tabBar button {
    padding-left: 16px;
    padding-right: 16px;
  }

  .inputPageGrid .ModalPopup .ModalContent,
  .electricityModel .ModalPopup .ModalContent,
  .addUserModel .ModalPopup .ModalContent {
    height: 500px;
  }

  .userProfileBox .profileTitle {
    word-break: break-all;
  }

  .emissionsAmt {
    font-size: 36px;
  }

  .toolTipBox {
    width: 180px;
    top: 30px !important;
    left: 50% !important;
    transform: translateX(-50%);
  }

  .toolTipBox:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0px;
    left: 53%;
    transform: rotate(135deg);
  }

  .chartInner {
    /* min-height: 105px; */
    transform: translate(-50%, -105%);
  }

  .blankTd {
    display: none;
  }
}

@media screen and (max-width: 454px) {
  .tabsSection .tabIcon {
    display: none;
  }

  .miscButton {
    top: 0;
    position: relative;
    right: 0;
    margin-bottom: 15px;
    width: 100%;
  }

  .miscButton button {
    width: 100%;
  }
}

@media screen and (max-width: 413px) {
  .tabLists li {
    margin-left: 14px;
    margin-right: 0;
  }

  .tabLists li:first-child {
    margin-left: 0px;
  }

  .tabLists li button {
    font-size: 13px;
  }

  .emissionsAmt {
    font-size: 32px;
  }

  .toolTipBox {
    width: 130px;
  }
}

@media screen and (max-width: 374px) {

  .fourBtnBar .button,
  .fourBtnBar .borderedButton {
    padding: 5px 8px;
  }
}

/* 
@media screen and (max-height: 999px) {
  .ModalPopup .ModalContent {
    height: 500px;
  }
}  */

@media screen and (max-height: 799px) {
  .ModalPopup .ModalContent {
    height: 280px;
  }

  .customFieldModel .ModalPopup .ModalContent {
    overflow-y: auto;
    height: 500px;
  }

  .customFieldModel .customFieldInfo {
    height: auto;
    overflow-y: visible;
  }

  .emmissionSummaryModel .ModalPopup .ModalContent {
    height: 400px;
  }

  /* .customFieldModel .ModalPopup .ModalContent {
    height: 350px;
  } */
  .autoHeightModal .ModalPopup .ModalContent {
    height: auto;
  }
}

@media screen and (max-height: 499px) {
  .emmissionSummaryModel .ModalPopup .ModalContent {
    height: 300px;
  }

  .customFieldModel .ModalPopup .ModalContent {
    overflow-y: auto;
    height: 300px;
  }
}

@media screen and (max-height: 299px) {
  .emmissionSummaryModel .ModalContent {
    height: 200px;
  }
}

.UserTypeSelect div[role*="button"] {
  display: none;
}

.Toastify__toast {
  font-family: "Montserrat", sans-serif !important;
}

.white_space {
  white-space: pre !important;
}

.white_space div {
  white-space: pre !important;
}

.white_space .responsiveString div {
  white-space: initial !important;
}

.responsiveString .input:focus,
.responsiveString input:focus-visible,
.responsiveString select:focus,
.responsiveString select:focus-visible {
  box-shadow: none;
}