/* @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Montserrat", sans-serif;
  /* font-family: "IBM Plex Sans", sans-serif; */
}

html,
body,
#root {
  height: 100%;
}

h1 {
  font-size: 48px;
  line-height: 62px;
}

h2 {
  font-size: 40px;
  line-height: 52px;
}

h3 {
  font-size: 34px;
  line-height: 44px;
}

h4 {
  font-size: 28px;
  line-height: 36px;
}

h5 {
  font-size: 24px;
  line-height: 30px;
}

h6 {
  font-size: 20px;
  line-height: 26px;
}

input {
  -webkit-appearance: value;
  -moz-appearance: value;
  appearance: value;
}

.signinInfoBox {
  height: calc(100% - 65px);
}

* {
  box-sizing: border-box;
}

@layer components {
  .input {
    @apply w-full p-2 border border-silver-300 rounded-lg;
  }

  .label {
    @apply text-sm text-silver-600 block mb-1;
  }

  .button {
    @apply bg-primary-500 text-white text-base rounded-full p-2.5;
  }
}
